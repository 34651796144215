
export default function SuccessPopup(prop : any) {
    const {setOpenPopUp, msg, title} = prop;
    
    return (
        <div className='popup-wrp popup-show fade noti-popup'>
            <div className="pop pop-content">
                <div className="pop-head">
                    <h5>{title}</h5>
                    <div className="pop-close" onClick={()=> setOpenPopUp(false)}></div>
                </div>
                <div className="pop-body">
                    <p>{msg} </p>
                    <div className="d-flex signup-login-btn">
                        <div className="login" onClick={()=>  {setOpenPopUp(false);}}>Okay</div>
                        {/* <div className="signup" onClick={() => {navigate("/signup"); setOpenPopUp(false);}} >Signup</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
