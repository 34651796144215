import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import FooterComponent from "../components/FooterComponent";

export default function Promotion() {
  const [filter, setFilter] = useState<string>("All");
  return (
    <>
      <div className="promotion-page">
        <div className="filter-list">
          <div className={`badge ${
              filter === "All" ? "active" : ""
            }`} onClick={() => {console.log("call ALL");
             setFilter("All")}}>
            All
          </div>
          <div
            className={`badge ${
              filter === "Welcome Offer" ? "active" : ""
            }`}
            onClick={() => setFilter("Welcome Offer")}
          >
            Welcome Offer
          </div>
          <div
            className={`badge ${
              filter === "Slot" ? "active" : ""
            }`}
            onClick={() => setFilter("Slot")}
          >
            Slot
          </div>
          <div
            className={`badge ${
              filter === "Casion" ? "active" : ""
            }`}
            onClick={() => setFilter("Casion")}
          >
            Casion
          </div>
          <div
            className={`badge ${
              filter === "Sport" ? "active" : ""
            }`}
            onClick={() => setFilter("Sport")}
          >
            Sport
          </div>
        </div>
        <div className="add-code">
          <p>
            {" "}
            Add Promotion Code <FontAwesomeIcon icon={faAngleDown} />
          </p>
          <div className="apply">
            <input type="text" placeholder="Please enter the Promotion Code" />
            <button className="apply-btn">Apply</button>
          </div>
        </div>
        <div className="promotion-list">
          <div className="promotion-box">
            <img
              src="https://img.m156b.com/upload/h5Announcement/image_167311.jpg"
              alt="Img"
            />
            <span className="new-tag">New</span>
            <div className="pr-info">
              <h5>100% Refund on A.F.C. Bournemouth</h5>
              <p>AFC Boumemouth Welcome Offer 2024/08/16 - 2025/05/25</p>

              <div className="btn-depo-deta">
                <button className="deposit">Deposit</button>
                <button className="detail">Detail</button>
              </div>
            </div>
          </div>
          <div className="promotion-box">
            <img
              src="https://img.m156b.com/upload/h5Announcement/image_167311.jpg"
              alt="Img"
            />
            <span className="new-tag">New</span>
            <div className="pr-info">
              <h5>100% Refund on A.F.C. Bournemouth</h5>
              <p>AFC Boumemouth Welcome Offer 2024/08/16 - 2025/05/25</p>

              <div className="btn-depo-deta">
                <button className="deposit">Deposit</button>
                <button className="detail">Detail</button>
              </div>
            </div>
          </div>
          <div className="promotion-box">
            <img
              src="https://img.m156b.com/upload/h5Announcement/image_167311.jpg"
              alt="Img"
            />
            <span className="new-tag">New</span>
            <div className="pr-info">
              <h5>100% Refund on A.F.C. Bournemouth</h5>
              <p>AFC Boumemouth Welcome Offer 2024/08/16 - 2025/05/25</p>

              <div className="btn-depo-deta">
                <button className="deposit">Deposit</button>
                <button className="detail">Detail</button>
              </div>
            </div>
          </div>
          <div className="promotion-box">
            <img
              src="https://img.m156b.com/upload/h5Announcement/image_167311.jpg"
              alt="Img"
            />
            <span className="new-tag">New</span>
            <div className="pr-info">
              <h5>100% Refund on A.F.C. Bournemouth</h5>
              <p>AFC Boumemouth Welcome Offer 2024/08/16 - 2025/05/25</p>

              <div className="btn-depo-deta">
                <button className="deposit">Deposit</button>
                <button className="detail">Detail</button>
              </div>
            </div>
          </div>
          <div className="promotion-box">
            <img
              src="https://img.m156b.com/upload/h5Announcement/image_167311.jpg"
              alt="Img"
            />
            <span className="new-tag">New</span>
            <div className="pr-info">
              <h5>100% Refund on A.F.C. Bournemouth</h5>
              <p>AFC Boumemouth Welcome Offer 2024/08/16 - 2025/05/25</p>

              <div className="btn-depo-deta">
                <button className="deposit">Deposit</button>
                <button className="detail">Detail</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
