import {
  faAngleLeft,
  faLeftLong,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  arcadeMenu,
  casinoMenu,
  fishingMenu,
  lotteryMenu,
  slotMenu,
  tableMenu,
} from "../common/common";

export default function SearchGame(prop: any) {
  const { closePopUp, casino, activeList, setSearchPlatforms, setSearchText, searchText, handleSearchCasino } = prop;
  const getCasinoName = (_value: any) => {
    return (
      <>
        <div className="col-4">
          <div
            className={`green-btn ${
              activeList.includes(_value.platform) ? "active" : ""
            }`}
            onClick={() => setSearchPlatforms(_value.platform)}
          >
            {_value.platform}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="game-search">
        <div className="search-bar">
          <div className="back" onClick={() => {closePopUp(false); handleSearchCasino()}}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div className="search-fild">
            <input type="text" onChange={(e:any)=> setSearchText(e.target.value)} value={searchText} placeholder="Search Games"/>
          </div>
          <div className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>

        <div className="provider">
          <p>Provider</p>

          <div className="row">
            {casino === "Casino" &&
              casinoMenu.map((_value) => getCasinoName(_value))}
            {casino === "Slot" &&
              slotMenu.map((_value) => casinoMenu.map((_value) => getCasinoName(_value)))}
            {casino === "Table" &&
              tableMenu.map((_value) => casinoMenu.map((_value) => getCasinoName(_value)))}
            {casino === "Fishing" &&
              fishingMenu.map((_value) => casinoMenu.map((_value) => getCasinoName(_value)))}
            {casino === "Arcade" &&
              arcadeMenu.map((_value) => casinoMenu.map((_value) => getCasinoName(_value)))}
            {casino === "Lottery" &&
              lotteryMenu.map((_value) => casinoMenu.map((_value) => getCasinoName(_value)))}
            {/* <div className="col-4">
              <div className="green-btn active">Sexy</div>
            </div>
            <div className="col-4">
              <div className="green-btn">Evolution</div>
            </div>
            <div className="col-4">
              <div className="green-btn">Pragmatic Play</div>
            </div> */}
          </div>
        </div>
        {/* <div className="game-type">
                    <p>Game Type</p>

                    <div className="row">
                        <div className="col-4">
                            <div className='green-btn active'>
                                Top Game
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='green-btn'>
                                Baccarat
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='green-btn'>
                                Game Show
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='green-btn'>
                                Roulette
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='green-btn'>
                                Sic Bo
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='green-btn'>
                                Poker
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='green-btn'>
                                Blackjack
                            </div>
                        </div>
                        <div className="col-4">
                            <div className='green-btn'>
                                First Person
                            </div>
                        </div>
                       
                    </div>
                </div> */}

        <div className="confirm-btn" onClick={()=> { closePopUp(false); handleSearchCasino()}}>
          <button className="btn-c">Confirm</button>
        </div>
      </div>
    </>
  );
}
