import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import walletNumber from "../assets/images/walletNumber.png";

export default function CashOutPayment(prop: any) {
  const {
    depositForm,
    handleDepositForm,
    Validator,
    handleImageUpload,
    onDepositSubmit,
    methodDetail,
    setDepositPopUp,
    setMethodDetail,
    setDepositForm,
    setDepositTotalAmountTab,
    setDepositTab,
    setDepositAmountTab
  } = prop;

  const [file, setFile] = useState<any>({ name: "" });
  const handleShowPrivew = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const [timeLeft, setTimeLeft] = useState(10 * 60); 
  useEffect(() => {
    if (timeLeft <= 0) {
      setDepositPopUp(false);
      setMethodDetail(null);
      setDepositTotalAmountTab(0);
      setDepositAmountTab(0);
      setDepositTab("");
      setDepositForm({ ...depositForm, amount: "0" });
      setDepositForm({ ...depositForm, image: "" });
      setDepositForm({ ...depositForm, transactionId: "" });
      return
    }; // Stop the timer when it reaches 0

    const interval = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000); // Decrease every second

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [timeLeft]);

  // Function to format time as MM:SS
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    // return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <>
      <div className="cash-out-pay">
        <div className="page-header">
          <h5>Cash Out Payment</h5>
        </div>
        <div className="page-content">
          <p>
            Transaction ID: <span>172908508084618920</span>
          </p>
          <p>
            Transaction Create Time: <span>2024-10-16 18:54:40 (GMT+5.5)</span>
          </p>
        </div>
        <div className="page-content text-center">
          <p className="gray">
            Please ensure amount to deposit is the same as transfered amount.
            company will not be liable for missing funds due to incorrect
            information.
          </p>
          <p className="gray">
            Please use phone number registered on company for cash out, deposits
            with 3rd party phone numbers are restricted
          </p>
          <p className="dark">
            Please <span>Cash Out</span> to the account below within{" "}
            <span>{formatTime(timeLeft)}</span> minutes after submitting the deposit form.{" "}
          </p>
        </div>
        <div className="page-content">
          <div className="contact-form">
            <div className="text-center">
              <img src={walletNumber} alt="walletNumber" />
              <p className="wallet-num">{methodDetail?.accountNo}</p>
              <span className="copy-btn">
                {" "}
                <FontAwesomeIcon className="mr" icon={faCopy} onClick={()=>""}/>
                Copy
              </span>
              <p className="amount">
                Amount : <span>{depositForm.amount}</span>
              </p>
              <span className="copy-btn">
                {" "}
                <FontAwesomeIcon className="mr" icon={faCopy} />
                Copy
              </span>
              <form action="">
                <div className="form-group">
                  <label htmlFor="">Phone Number</label>
                  <input
                    type="number"
                    placeholder="Please input Number"
                    value={depositForm.mobileNo}
                    name="mobileNo"
                    onChange={(e: any) => handleDepositForm(e)}
                  />
                  {Validator.current.message(
                    "mobileNo",
                    depositForm.mobileNo,
                    "required|phone"
                  ) ? (
                    <span className="error" style={{ color: "red" }}>
                      {Validator.current.message(
                        "mobileNo",
                        depositForm.mobileNo,
                        "required|phone"
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="">Transaction ID</label>
                  <input
                    type="text"
                    placeholder="Please input data"
                    value={depositForm.transactionId}
                    name="transactionId"
                    onChange={(e: any) => handleDepositForm(e)}
                  />
                  {Validator.current.message(
                    "transactionId",
                    depositForm.transactionId,
                    "required"
                  ) ? (
                    <span className="error" style={{ color: "red" }}>
                      {Validator.current.message(
                        "transactionId",
                        depositForm.transactionId,
                        "required"
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="">
                    Upload Receip 
                    {/* <span className="reset">Reset Receipt</span> */}
                  </label>
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => {
                      handleImageUpload(e, "image");
                      handleShowPrivew(e);
                    }}
                  />
                  {/* <p className="rec-txt">Receipt Is Not Mandatory</p> */}
                  <div className="dropImages">Drop file here</div>
                </div>
                {depositForm.image && (
                  <a href={"#"} target="_blank">
                    <img
                      className="mt-3"
                      style={{ maxWidth: "300px" }}
                      src={file}
                      alt="Image"
                    />
                  </a>
                )}
                <button type="submit" className="submit-btn" onClick={(e) => onDepositSubmit(e)}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
