import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_API, USER_API } from "../common/common";
import { postApi } from "../service";
import { Logout } from "../common/Funcation";
import SuccessPopup from "../components/successPopUp";

export default function Inbox() {
  const navigate = useNavigate();
  const [inBoxDetails, setInBoxDetails] = useState<any>({});
  const [openPopUp, setOpenPopUp] = useState(false);
  const [popUpItem, setPopUpItem] = useState<any>({});
  useEffect(() => {
    getInboxDetails();
    return () => {};
  }, []);

  const getInboxDetails = async () => {
    let data = {
      api: ADMIN_API.PLAYER_INBOX,
      //   value: {
      //     domain: window.location.hostname,
      //   },
    };
    await postApi(data)
      .then(function (response) {
        if (response.data.data) {
          console.log("getInboxDetails: response:   ", response);
          const groupData = groupByDate(response.data.data.inboxDetails);
          console.log("getInboxDetails: response: groupData:", groupData);
          setInBoxDetails(groupData);
        } else {
          navigate("/404");
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          Logout();
          window.open("/login");
        } else {
          navigate("/404");
        }
      });
  };

  const groupByDate = (data: any) => {
    return data.reduce((acc: any, item: any) => {
      // Extract the date part from createdAt
      const date = new Date(item.createdAt).toISOString().split("T")[0]; // "YYYY-MM-DD"

      // If the date key doesn't exist, initialize it as an array
      if (!acc[date]) {
        acc[date] = [];
      }

      // Push the current notification to the respective date group
      acc[date].push(item);

      return acc;
    }, {}); // Initialize accumulator as an empty object
  };

  const getTimeFromDate = (createdAt: string) => {
    const date = new Date(createdAt);

    // Extract the time (HH:MM:SS)
    const hours = date.getUTCHours().toString().padStart(2, "0"); // Hours in UTC (24-hour format)
    const minutes = date.getUTCMinutes().toString().padStart(2, "0"); // Minutes
    // const seconds = date.getUTCSeconds().toString().padStart(2, '0'); // Seconds

    const time = `${hours}:${minutes}`;
    return time;
  };
  const handleBackButton = (key: string) => {
    console.log("hello im location to home");
    // Navigate back to home and set state to show the popup
    navigate("/home", { state: { showPopup: true } });
  };

  useEffect(() => {
    if (openPopUp && !popUpItem.isRead) {
      inboxUpdate();
    }
  }, [popUpItem]);

  const inboxUpdate = async () => {
    let data = {
      api: ADMIN_API.PLAYER_INBOX_AS_UPDATE,
      value: {
        id: popUpItem?._id,
      },
    };
    await postApi(data)
      .then(function (response) {})
      .catch((err) => {});
  };
  return (
    <>
      <div className="mob-header">
        <div className="back">
          <img
            src="../../images/extra-icon/angle-left-white.png"
            height={22}
            onClick={() => handleBackButton("/")}
          />
        </div>
        <div className="center">
          <h5>Inbox</h5>
        </div>
        <div className="help">
          <img
            src="../../images/extra-icon/message.png"
            alt="Help"
            height={25}
          />
          <span>Help</span>
        </div>
      </div>
      <div className="announce-box">
        {inBoxDetails &&
          Object.keys(inBoxDetails).map((key) => {
            return (
              <>
                <div className="announce">
                  <div className="announce-date-time">
                    <img src={"../../images/extra-icon/date-time.png"} />
                    <div>{key}</div>
                  </div>
                  {inBoxDetails[key] &&
                    inBoxDetails[key].map((item: any) => {
                      return (
                        <>
                          <div
                            className="announce-part"
                            onClick={() => {
                              setPopUpItem(item);
                              setOpenPopUp(true);
                            }}
                          >
                            <div className="announce-left">
                              <img
                                src={"../../images/extra-icon/announce3.png"}
                              />
                            </div>
                            <div className="announce-right">
                              <div className="announce-right-top">
                                <div className="announce-short-title">
                                  {/* Hi Anas! Congratulation! */}
                                  {item?.title}
                                </div>
                                <div className="announce-time">
                                  {getTimeFromDate(item?.createdAt)}
                                </div>
                              </div>
                              <div className="announce-short-details">
                                {/* Congratulation for registeration in... */}
                                {item?.message}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            );
          })}

        {/* <div className="announce">
          <div className="announce-date-time">
            <img src={"../../images/extra-icon/date-time.png"} />
            <div>10/11/2023</div>
          </div>

          <div className="announce-part">
            <div className="announce-left">
              <img src={"../../images/extra-icon/announce3.png"} />
            </div>
            <div className="announce-right">
              <div className="announce-right-top">
                <div className="announce-short-title">
                  Hi Anas! Congratulation!
                </div>
                <div className="announce-time">1:33am</div>
              </div>
              <div className="announce-short-details">
                Congratulation for registeration in...
              </div>
            </div>
          </div>

          <div className="announce-part">
            <div className="announce-left">
              <img src={"../../images/extra-icon/announce3.png"} />
            </div>
            <div className="announce-right">
              <div className="announce-right-top">
                <div className="announce-short-title">
                  Hi Anas! Congratulation!
                </div>
                <div className="announce-time">1:33am</div>
              </div>
              <div className="announce-short-details">
                Congratulation for registeration in...
              </div>
            </div>
          </div>

          <div className="announce-part">
            <div className="announce-left">
              <img src={"../../images/extra-icon/announce3.png"} />
            </div>
            <div className="announce-right">
              <div className="announce-right-top">
                <div
                  className="announce-short-title"
                  style={{ color: "#828282" }}
                >
                  Hi Anas! Congratulation!
                </div>
                <div className="announce-time">1:33am</div>
              </div>
              <div className="announce-short-details">
                Congratulation for registeration in...
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {openPopUp && (
        <SuccessPopup
          setOpenPopUp={setOpenPopUp}
          msg={popUpItem?.message}
          title={popUpItem?.title}
        />
      )}
    </>
  );
}
