import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { getImageUrl } from '../common/Funcation';
import logo from "../assets/logo.png"

export default function LoaderMain() {
    const DD = useSelector((e: any) => e.domainDetails);
    const [domainDetails, setDomainDetails] = useState(DD);

  return (
    <div className='loader-main'>
        <div className="logo-box">
            {/* <img src={getImageUrl(domainDetails?.logo)} alt="Logo" /> */}
            <img src={logo} alt="Logo" />
        </div>
    </div>
  )
}