import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

export default function PersonalInfo() {
  const navigate = useNavigate();
  const HeaderData = useSelector((e: any) => e.userData);
  const handleBackButton = (key:string) => {
    console.log("hello im location to home");
    // Navigate back to home and set state to show the popup
    navigate("/home", { state: { showPopup: true } });
  };
  return (
    <>
      <div className="mob-header">
        <div className="back">
          <img
            src="../../images/extra-icon/angle-left-white.png"
            height={22}
            onClick={() => handleBackButton("/")}
          />
        </div>
        <div className="center">
          <h5>Personal Info</h5>
        </div>
        <div className="help">
          <img
            src="../../images/extra-icon/message.png"
            alt="Help"
            height={25}
          />
          <span>Help</span>
        </div>
      </div>

      <div className="personal-info">
        <div className="sports-image">
          <div
            style={{
              overflow: "hidden",
              height: "170px",
              borderRadius: "15px",
            }}
          >
            <img
              className="pattern-image"
              src={"../../images/extra-icon/sports-bg.png"}
            />
          </div>
          <div className="league-palette">
            <img
              src={"../../images/extra-icon/1704320386093.png"}
            />
          </div>
        </div>

        <div className="user-fullname">
          <span>{HeaderData?.user_name}</span>
          <span className="user-league">Bronze</span>
          <br />
          <span className="user-username">@{HeaderData?.user_name}</span>
        </div>

        <div className="vip-stick">
          <div className="vip-stick-div1">
            <div>VIP Points (VP)</div>
            <div style={{ fontSize: "18px" }}></div>
          </div>
          <div className="vip-stick-div2" id="myvip">
            <div>
              My VIP{" "}
              <span
                style={{
                  fontSize: "25px",
                  marginLeft: "10px",
                  marginTop: "-5px",
                }}
              >
                {" "}
                »
              </span>
            </div>
          </div>
        </div>

        <div className="required-board">
          <table>
            <tbody>
              <tr>
                <td>
                  <img
                    src={"../../images/extra-icon/exclamation-white.png"}
                    height="25px"
                  />
                </td>
                <td>
                  Please complete the verification below before you proceed with
                  the withdrawal request.
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ padding: "15px" }}>
            <div className="req-ttl req-ttl2">Contact Info</div>
            <span className="req2">Phone Number</span>
            <span className="req2">Email</span>{" "}
          </div>
        </div>

        <div className="user-info-box">
          <div className="user-fullname-title">
            <div className="title">Full Name</div>
            <div className="reg-date">Registered Date {moment(HeaderData?.createdAt).format('DD-MM-YYYY')}</div>
          </div>
          <div style={{ color: "orange" }}>{HeaderData?.user_name}</div>

          <br />
          <div className="user-info-title">
            Phone number 
            {/* <span id="add-number-btn">+ Add</span> */}
          </div>
          <div className="user-detail">
            <div className="detail-left">{HeaderData?.mobileNumber}</div>
            <div className="detail-right" id="notverified1">
              <i
                className="fa-solid fa-triangle-exclamation"
                aria-hidden="true"
              ></i>{" "}
              Not Verified
            </div>{" "}
          </div>

          <br />
          <div className="user-info-title">Email</div>
          <div className="user-detail">
            <div className="detail-left">{HeaderData?.email}</div>
            <div className="detail-right" id="notverified2">
              <i
                className="fa-solid fa-triangle-exclamation"
                aria-hidden="true"
              ></i>{" "}
              Not Verified
            </div>{" "}
          </div>

          <br />
          <div className="user-info-title">Birthday</div>
          <div className="user-detail">
            <div className="detail-left">10/6/2024</div>
            <div
              className="detail-right"
              style={{ color: "#777", textDecoration: "none" }}
            >
              Verified
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
